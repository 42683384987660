<template>
  <div id="contact">
    <div class="about">
      <h2>À propos</h2>
      <div class="description">
        <p>
          <img src="../assets/images/who-picture.jpg" alt="Alex RAYER">
          Pour toute demande concernant mon travail ou demandes professionnelles, merci de me contacter via ce
          formulaire.<br/>
          <br/>
          Photographe de paysages, j'affectionne particulièrement les lieux escarpés et dépeuplés, ces lieux où l'on se
          sent petit et on ressent la puissance de la nature.<br/>
          <br/>
          À mes débuts, par passion, j'ai consacré beaucoup de temps à apprendre la technique de la photographie par mes
          propres moyens afin l'appliquer à cette passion grandissante. Après un cursus en informatique, j'ai fais le
          choix de porter cette passion à l'avant de ma vie professionnelle et d'étudier la photographie.<br/>
          Cette formation m'a permis d'acquérir de nombreuses compétences et techniques en photographie et ainsi
          compléter mes savoir-faire dans ce domaine.<br/>
          Mes capacités s'étendent maintenant au-delà de la photographie de paysages, en passant par le studio et la
          réalisation de courtes vidéos.<br/>
          <br/>
          Egalement intéressé par la vidéo j'ai eu l'occasion de réaliser quelques court-métrages et voudrait aussi
          développer mes compétences dans ce domaine.
        </p>
      </div>
    </div>
    <div class="form">
      <h2>Me contacter</h2>
      <form id="contact-form">
        <div class="form-messages">
          <span v-for="message in messages"
                :class="{'success': message.type === 'success', 'error': message.type === 'error'}">
            {{ message.message }}
          </span>
        </div>
        <div class="form-row two-input">
          <div class="form-input">
            <label for="form-lastname">Nom</label>
            <input type="text" id="form-lastname" name="lastname" v-model="lastName" required>
            <div class="errors">
              <span class="error" v-for="error in lastNameErrors">{{ error }}</span>
            </div>
          </div>
          <div class="form-input">
            <label for="form-name">Prénom</label>
            <input type="text" id="form-name" name="name" v-model="firstName" required>
            <div class="errors">
              <span class="error" v-for="error in firstNameErrors">{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-input">
            <label for="form-mail">E-mail</label>
            <input type="email" id="form-mail" name="mail" v-model="mail" required>
            <div class="errors">
              <span class="error" v-for="error in mailErrors">{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-input">
            <label for="form-message">Message</label>
            <textarea id="form-message" name="message" v-model="message" required></textarea>
            <div class="errors">
              <span class="error" v-for="error in messageErrors">{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-submit">
            <button type="button" @click="submit"><span>Envoyer</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "@/http/config";
import qs from "qs";

export default {
  name: "Contact",
  data() {
    return {
      lastName: null,
      lastNameErrors: [],
      firstName: null,
      firstNameErrors: [],
      mail: null,
      mailErrors: [],
      message: null,
      messageErrors: [],
      messages: []
    };
  },
  methods: {
    submit() {
      this.messages = [];
      this.emptyErrors();
      let form = document.getElementById("contact-form");
      if (form.reportValidity() && this.checkForm()) {
        http.put("/contact", qs.stringify({
          lastName: this.lastName,
          firstName: this.firstName,
          mail: this.mail,
          message: this.message
        })).then(value => {
          console.log(value);
          this.messages.push({type: "success", message: "Your message have been sent."});
          this.emptyForm();
        }).catch(reason => {
          let errors = reason.response.data.errors;
          for (let error in errors) if (this[error + "Errors"] !== undefined) this[error + "Errors"].push(errors[error]);
        });
      }
    },
    checkForm() {
      const errorMessage = "This value can't be empty";
      let e = true;
      if (this.lastName === null) {
        e = false;
        this.lastNameErrors.push(errorMessage);
      }
      if (this.firstName === null) {
        e = false;
        this.firstNameErrors.push(errorMessage);
      }
      if (this.mail === null) {
        e = false;
        this.mailErrors.push(errorMessage)
      }
      if (this.message === null) {
        e = false;
        this.messageErrors.push(errorMessage);
      }
      return e;
    },
    emptyForm() {
      ["lastName", "firstName", "mail", "message"].forEach(e => this[e] = null);
    },
    emptyErrors() {
      ["lastNameErrors", "firstNameErrors", "mailErrors", "messageErrors"].forEach(e => {
        this[e] = [];
      });
    }
  }
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  div#contact {
    margin: 0 !important;

    & > div {
      margin: auto;
      width: 90% !important;
    }
  }
}

@media all and (min-width: 801px) {
  div#contact {
    flex-direction: row !important;
  }
}

div#contact {
  display: flex;
  max-width: 2000px;
  margin: auto;

  & > div {
    width: 45%;
    margin: 0 2.5%;
  }

  & div.about {
    & div.description {
      display: flex;
      padding: 2px 20px;

      & p {
        text-align: justify;
        font-size: 1.1em;

        img {
          @margin: 20px;
          margin: 0 0 @margin @margin;
          float: right;
          max-width: 200px;
          max-height: 200px;
        }
      }
    }
  }

  & div.form {
    & form {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;

      & > div.form-messages {
        display: flex;
        flex-direction: column;

        & > span {
          &.success {
            color: green;
          }

          &.error {
            color: red;
          }
        }
      }

      & > div.form-row {
        display: flex;
        margin: 10px 0;

        &.two-input {
          justify-content: space-between;

          & div.form-input {
            flex: none;
            width: 48%;
          }
        }

        & > div.form-input {
          display: flex;
          flex-direction: column;
          width: 100%;

          & label {
            margin-bottom: 4px;
          }

          & input, & textarea {
            height: 30px;
            border-radius: 0;
            border: 2px solid black;
            background-color: white;
            font-size: 19px;
            outline: none;
          }

          & textarea {
            resize: vertical;
            min-height: 100px;
            font-size: 15px;
          }

          & div.errors {
            display: flex;
            flex-direction: column;
            color: red;
            font-size: 15px;

            & span.error:nth-child(1) {
              margin-top: 3px;
            }
          }
        }

        & > div.form-submit button {
          margin: 10px auto;
          padding: 9px 15px;
          border: 3px solid black;
          cursor: pointer;
          text-decoration: none;
          transition: background-color .3s, color .3s;
          background-color: white;
          font-size: 18px;

          & span {
            position: relative;
            color: black;
          }

          &:hover {
            background-color: black;

            & span {
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
